<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		:class="['xs', 'sm'].includes(windowWidth) ? 'p-2' : 'p-3'"
		class="animated fadeIn">
		<template v-if="payoutMethods.length > 1">
			<div
				:class="{ 'no-gutters': ['xs', 'sm'].includes(windowWidth) }"
				class="row border-bottom pb-3 mb-3">
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? 'col-10' : 'col-11'">
					<div
						:class="['xs', 'sm'].includes(windowWidth) ? 'btn-group-vertical w-100' : 'btn-group'"
						class="btn-group-lg payment-method"
						role="group">
						<b-button
							v-for="item in payoutMethods"
							:key="item.id"
							:variant="selectedPayoutMethod === item.attributes.method_code_name ? 'primary' : 'light'"
							@click="selectedPayoutMethod = item.attributes.method_code_name">
							{{ translate(item.attributes.method_code_name) }}
						</b-button>
					</div>
				</div>
				<div
					v-if="canViewVideo"
					:class="['xs', 'sm'].includes(windowWidth) ? 'col-2' : 'col-1'">
					<a
						class="text-decoration-none link-primary mr-2"
						style="font-size: 25px; float: right;"
						@click="showVideoModal(true)">
						<i class="fas fa-question-circle" />
					</a>
				</div>
			</div>
		</template>
		<template v-if="selectedPayoutMethod === 'ipayout'">
			<div class="row">
				<div class="col-12">
					<b-alert
						variant="warning"
						class="text-center"
						show>
						{{ translate('withdraw_announcement', { companyName }) }}
					</b-alert>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-7 col-lg-8 mb-3">
					<b-card
						no-body
						class="h-100">
						<template v-slot:header>
							<h4 class="mb-0">
								{{ translate('payout_system') }}
							</h4>
						</template>
						<b-card-body class="pb-0">
							<div class="text-center mb-4">
								<img
									:src="require(`@/assets/images/themes/${themeName}/wallets/ipayout.png`)"
									align="center">
							</div>
							<b-card-title class="h6 text-center">
								{{ translate('payout_system_title', { companyName }) }}
							</b-card-title>
							<b-card-text v-html="translate('payout_system_text')" />
						</b-card-body>
						<div
							v-if="iPayoutUsername"
							class="px-3">
							<b-alert
								variant="info"
								class="mb-0"
								show>
								<div class="h4 mb-0">
									{{ translate('username') }}: <b>{{ iPayoutUsername }}</b>
								</div>
							</b-alert>
						</div>
						<div class="p-3 d-flex align-items-end">
							<form
								id="payoutForm"
								:action="`https://${merchantName}.${eWalletRoute}/MemberLogin.aspx`"
								method="post"
								target="_blank">
								<input
									type="hidden"
									name="secKey"
									:value="userKey">
							</form>
							<b-button
								type="submit"
								variant="primary"
								size="md"
								class="w-100"
								block
								@click="loginWallet">
								{{ translate('open_website') }}
							</b-button>
						</div>
					</b-card>
				</div>
				<div
					:class="{ 'mb-3': !['xs', 'sm'].includes(windowWidth) }"
					class="col-12 col-md">
					<b-card
						no-body
						class="h-100">
						<template v-slot:header>
							<h4 class="mb-0">
								{{ translate('transfer_balance') }}
							</h4>
						</template>
						<b-card-body class="pb-0">
							<b-card-text>
								{{ translate('transfer_balance_text') }}
							</b-card-text>
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'py-2' : 'p-2'"
								class="row">
								<div class="col-6 pl-4">
									{{ translate('balance_available') }}
								</div>
								<div class="col-6 font-weight-bold text-right">
									{{ balance['commission'] }}
								</div>
							</div>
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'py-2' : 'p-2'"
								class="row">
								<form
									class="form-group col-12 mb-0"
									@change="clearError($event.target.name)"
									@keydown="clearError($event.target.name)">
									<div
										id="amountInput"
										class="form-group row"
										:errors="errors['amount']">
										<div class="col-6">
											<label
												for="amount"
												class="label pl-2">
												{{ translate('amount') }}
												<span class="text-danger">*</span>
											</label>
										</div>
										<div class="col-6">
											<input
												id="amount"
												v-model="amount"
												name="amount"
												type="number"
												:class="hasError('amount') ? 'is-invalid' : ''"
												class="form-control form-control-sm text-right"
												aria-describedby="amount"
												placeholder="$0.00"
												@dataChanged="amount = $event">
										</div>
										<div class="col-12">
											<template v-if="hasError('amount')">
												<p
													v-for="error in errors['amount']"
													:key="error"
													class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
													v-text="error" />
											</template>
										</div>
									</div>
									<div
										id="passwordInput"
										class="form-group row mb-0"
										:errors="errors['transaction_password']">
										<div class="col-6">
											<label
												for="transaction_password"
												class="label pl-2">
												{{ translate('wallet_password') }}
												<span class="text-danger">*</span>
												<b-button
													v-b-modal="'wallet-password-modal'"
													variant="link"
													class="p-0 ml-2 align-bottom text-dark">
													<i class="fas fa-question-circle" />
												</b-button>
											</label>
										</div>
										<div class="col-6 align-self-center">
											<input-password
												id="transaction_password"
												v-model="password"
												name="transaction_password"
												:placeholder="translate('password')"
												:input-class="`${hasError('transaction_password') ? 'is-invalid' : ''} form-control form-control-sm text-left`"
												toggle-class="form-control form-control-sm" />
										</div>
										<div class="col-12">
											<template v-if="hasError('transaction_password')">
												<p
													v-for="error in errors['transaction_password']"
													:key="error"
													class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
													v-text="error" />
											</template>
										</div>
									</div>
								</form>
							</div>
						</b-card-body>
						<div class="p-3 d-flex align-items-end">
							<b-button
								:disabled="loading || !isFormReady"
								:class="(loading || !isFormReady) ? '' : 'media-btn'"
								type="submit"
								variant="primary"
								size="md"
								block
								class="btn bg-primary-alt"
								@click="verifyTransfer">
								{{ translate('transfer') }}
							</b-button>
						</div>
					</b-card>
				</div>
				<b-modal
					id="wallet-password-modal"
					:title="translate('wallet_password')"
					ok-only>
					{{ translate('wallet_password_modal', { companyName }) }}
				</b-modal>
			</div>
		</template>
		<template v-if="selectedPayoutMethod === 'tw_local_eft'">
			<div class="row">
				<div class="col-12">
					<b-alert
						variant="warning"
						class="text-center"
						show>
						{{ translate('withdraw_tw_local_eft_announcement', { companyName }) }}
					</b-alert>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-md-7 col-lg-8 mb-3">
					<b-card
						no-body
						class="h-100">
						<template v-slot:header>
							<h4 class="mb-0">
								{{ translate('tw_local_eft_system') }}
							</h4>
						</template>
						<b-card-body class="pb-0">
							<b-card-title class="h6 text-center">
								{{ translate('tw_local_eft_title') }}
							</b-card-title>
							<b-form>
								<b-row>
									<b-col :cols="['xs', 'sm', 'md'].includes(windowWidth) ? 12 : 6">
										<b-form-group
											:label="translate('account_name')"
											label-for="account-name">
											<b-form-input
												id="account-name"
												v-model="bankInfo.account_name"
												:disabled="getBankInfoUtil.data.loading"
												:placeholder="translate('account_name')"
												required />
										</b-form-group>
									</b-col>
									<b-col :cols="['xs', 'sm', 'md'].includes(windowWidth) ? 12 : 6">
										<b-form-group
											:label="translate('account_number')"
											label-for="account-number">
											<b-form-input
												id="account-number"
												v-model="bankInfo.account_number"
												:disabled="getBankInfoUtil.data.loading"
												:placeholder="translate('account_number')"
												required />
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col :cols="['xs', 'sm', 'md'].includes(windowWidth) ? 12 : 6">
										<b-form-group
											:label="translate('bank_name')"
											label-for="bank-name">
											<b-form-select
												id="bank-name"
												v-model="bankInfo.bank_id"
												:options="banks"
												:disabled="getBankInfoUtil.data.loading"
												:placeholder="translate('bank_name')"
												required>
												<template v-slot:first>
													<option :value="null">
														{{ translate('select_bank') }}
													</option>
												</template>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col :cols="['xs', 'sm', 'md'].includes(windowWidth) ? 12 : 6">
										<b-form-group
											:label="translate('branch_code')"
											label-for="branch-code">
											<b-form-select
												id="branch-code"
												v-model="bankInfo.branch_id"
												:disabled="!bankInfo.bank_id || getBankInfoUtil.data.loading"
												:options="bankBranches"
												:placeholder="translate('branch_code')"
												required>
												<template v-slot:first>
													<option :value="null">
														{{ translate('select_branch_code') }}
													</option>
												</template>
											</b-form-select>
										</b-form-group>
									</b-col>
								</b-row>
							</b-form>
							<b-button
								class="float-right"
								variant="primary"
								:disabled="saveBankInfoUtil.data.loading || getBankInfoUtil.data.loading || !bankInfo.bank_id || !bankInfo.branch_id || !bankInfo.account_name || !bankInfo.account_number || (savedBankInfo.account_name === bankInfo.account_name && savedBankInfo.account_number === bankInfo.account_number && savedBankInfo.bank_id === bankInfo.bank_id && savedBankInfo.branch_id === bankInfo.branch_id)"
								@click="saveBankInformation">
								<template v-if="saveBankInfoUtil.data.loading">
									<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
								</template>
								<template v-else>
									{{ translate('save') }}
								</template>
							</b-button>
						</b-card-body>
					</b-card>
				</div>
				<div
					:class="{ 'mb-3': !['xs', 'sm'].includes(windowWidth) }"
					class="col-12 col-md">
					<b-card
						no-body
						class="h-100">
						<template v-slot:header>
							<h4 class="mb-0">
								{{ translate('request_withdrawal') }}
							</h4>
						</template>
						<b-card-body class="pb-0">
							<b-card-text>
								{{ translate('withdraw_balance_text') }}
							</b-card-text>
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'py-2' : 'p-2'"
								class="row">
								<div class="col-6 pl-4">
									{{ translate('balance_available') }}
								</div>
								<div class="col-6 font-weight-bold text-right">
									{{ balance['commission'] }}
								</div>
							</div>
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'py-2' : 'p-2'"
								class="row">
								<form
									class="form-group col-12 mb-0"
									@change="clearError($event.target.name)"
									@keydown="clearError($event.target.name)">
									<div
										id="amountInput"
										class="form-group row"
										:errors="errors['amount']">
										<div class="col-6">
											<label
												for="amount"
												class="label pl-2">
												{{ translate('amount') }}
												<span class="text-danger">*</span>
											</label>
										</div>
										<div class="col-6">
											<input
												id="amount"
												v-model="amount"
												:disabled="!savedBankInfo.account_name"
												name="amount"
												type="number"
												:class="hasError('amount') ? 'is-invalid' : ''"
												class="form-control form-control-sm text-right"
												aria-describedby="amount"
												placeholder="$0.00"
												@dataChanged="amount = $event">
										</div>
										<div class="col-12">
											<template v-if="hasError('amount')">
												<p
													v-for="error in errors['amount']"
													:key="error"
													class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
													v-text="error" />
											</template>
										</div>
									</div>
									<div
										id="passwordInput"
										class="form-group row mb-0"
										:errors="errors['transaction_password']">
										<div class="col-6">
											<label
												for="transaction_password"
												class="label pl-2">
												{{ translate('wallet_password') }}
												<span class="text-danger">*</span>
												<b-button
													v-b-modal="'wallet-password-modal'"
													variant="link"
													class="p-0 ml-2 align-bottom text-dark">
													<i class="fas fa-question-circle" />
												</b-button>
											</label>
										</div>
										<div class="col-6 align-self-center">
											<input-password
												id="transaction_password"
												v-model="password"
												:disabled="!savedBankInfo.account_name"
												name="transaction_password"
												:placeholder="translate('password')"
												:input-class="`${hasError('transaction_password') ? 'is-invalid' : ''} form-control form-control-sm text-left`"
												toggle-class="form-control form-control-sm" />
										</div>
										<div class="col-12">
											<template v-if="hasError('transaction_password')">
												<p
													v-for="error in errors['transaction_password']"
													:key="error"
													class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
													v-text="error" />
											</template>
										</div>
									</div>
								</form>
							</div>
						</b-card-body>
						<div class="p-3 d-flex align-items-end">
							<b-button
								:disabled="loading || !isFormReady || !savedBankInfo.account_name"
								:class="(loading || !isFormReady || !savedBankInfo.account_name) ? '' : 'media-btn'"
								type="submit"
								variant="primary"
								size="md"
								block
								class="btn bg-primary-alt"
								@click="verifyWithdrawal">
								{{ translate('request') }}
							</b-button>
						</div>
					</b-card>
				</div>
				<b-modal
					id="wallet-password-modal"
					:title="translate('wallet_password')"
					ok-only>
					{{ translate('wallet_password_modal', { companyName }) }}
				</b-modal>
			</div>
		</template>
		<is-loading
			:loading="loadingPayoutMethods"
			:has-data="hasPayoutMethods"
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')" />
		<video-modal
			:modal-show="modalShow"
			:data="videoData"
			@saveProgress="saveVideoProgress"
			@close="closeModal" />
	</div>
</template>
<script>
import InputPassword from '@/components/InputPassword';
import IsLoading from '@/components/Loading';
import WindowSizes from '@/mixins/WindowSizes';
import { merchantName, eWalletRoute } from '@/settings/Wallets';
import { VIDEO_NAMES } from '@/settings/Videos';
import { PayoutMethods, Wallets as WalletMessages } from '@/translations';
import Alert from '@/util/Alert';
import Wallets from '@/util/Wallets';
import Videos from '@/util/Videos';
import VideoModal from '@/views/Videos/VideoModal';

export default {
	name: 'WalletsWithdrawal',
	messages: [PayoutMethods, WalletMessages],
	components: {
		InputPassword,
		IsLoading,
		VideoModal,
	},
	mixins: [WindowSizes],
	data() {
		return {
			summary: new Wallets(),
			createPayout: new Wallets(),
			loginPayout: new Wallets(),
			transfer: new Wallets(),
			updateAlert: new Wallets(),
			getPayoutMethods: new Wallets(),
			banksUtil: new Wallets(),
			bankBranchesUtil: new Wallets(),
			saveBankInfoUtil: new Wallets(),
			getBankInfoUtil: new Wallets(),
			video: new Videos(),
			alert: new Alert(),
			userKey: '',
			merchantName,
			eWalletRoute,
			amount: '',
			password: '',
			selectedPayoutMethod: '',
			errors: {},
			companyName: process.env.VUE_APP_TITLE,
			themeName: process.env.VUE_APP_THEME,
			modalShow: false,
			videoName: VIDEO_NAMES.withdrawal.name,
			videoCountries: VIDEO_NAMES.withdrawal.validCountries,
			videoData: {},
			iPayoutUsername: '',
			bankInfo: {
				account_name: '',
				account_number: '',
				bank_id: null,
				branch_id: null,
			},
			savedBankInfo: {
				account_name: '',
				account_number: '',
				bank_id: null,
				branch_id: null,
			},
			bankCode: '',
			branchCode: '',
		};
	},
	computed: {
		isFormReady() {
			const {
				amount,
				password,
			} = this;

			return !!amount.length > 0 && !!password.length;
		},
		balance() {
			try {
				const { balance } = this.summary.data.response.data.response;
				return balance;
			} catch (error) {
				return [];
			}
		},
		transferErrors() {
			return this.transfer.errors.errors;
		},
		loading() {
			return !!this.transfer.data.loading;
		},
		payoutMethods() {
			try {
				return this.getPayoutMethods.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loadingPayoutMethods() {
			return !!this.getPayoutMethods.data.loading;
		},
		hasPayoutMethods() {
			return !!this.payoutMethods.length;
		},
		canViewVideo() {
			return this.videoCountries.includes(this.$user.details().country ? this.$user.details().country.iso_code_2 : '');
		},
		banks() {
			try {
				const banks = this.banksUtil.data.response.data.data;
				return banks.map((bank) => ({
					value: bank.id,
					text: bank.attributes.code_name,
				}));
			} catch (error) {
				return [];
			}
		},
		bankBranches() {
			try {
				const branches = this.bankBranchesUtil.data.response.data.data;
				return branches.map((branch) => ({
					value: branch.id,
					text: branch.attributes.display_code_name,
				}));
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		selectedPayoutMethod() {
			this.clearAllErrors();
			this.clearForm();
			this.summary.getBalance();
		},
		transferErrors: {
			deep: true,
			handler(newVal) {
				this.errors = newVal;
			},
		},
		'bankInfo.bank_id': function bankId(newVal) {
			if (newVal !== null) {
				this.getBranches();
			}
		},
	},
	created() {
		this.getPayoutMethods.getPayoutMethods({ country_code: this.getStoredCountry() }).then((methods) => {
			const codeNames = methods.map((method) => method.attributes.method_code_name);

			if (codeNames.some((method) => method === 'ipayout')) {
				this.createPayout.createPayoutAccount(this.$user.details().id).then((response) => {
					this.iPayoutUsername = response.response.username;
					this.showAccountCreatedAlert(response.response.show_created_alert);
				});
			}

			if (codeNames.some((method) => method === 'tw_local_eft')) {
				this.getBankInformation();
				this.getBanks();
			}

			[this.selectedPayoutMethod] = codeNames;
		});
		this.summary.getBalance();
	},
	methods: {
		getBanks() {
			this.banksUtil.getBanks();
		},
		getBranches() {
			this.bankBranchesUtil.getBankBranches(this.bankInfo.bank_id);
		},
		getBankInformation() {
			this.getBankInfoUtil.getBankInformation().then((response) => {
				['account_name', 'account_number', 'bank_id', 'branch_id'].forEach((key) => {
					this.$set(this.savedBankInfo, key, response.attributes[key]);
					this.$set(this.bankInfo, key, response.attributes[key]);
				});
				this.bankCode = response.attributes.bank_code;
				this.branchCode = response.attributes.branch_code;
			});
		},
		saveBankInformation() {
			this.saveBankInfoUtil.saveBankInformation(this.bankInfo).then(() => {
				if (this.saveBankInfoUtil.data.response.status === 200) {
					this.alert.toast('success', this.translate('bank_information_updated'));
					this.getBankInformation();
				}
			});
		},
		hasError(field) {
			return typeof this.errors[field] !== 'undefined';
		},
		clearError(field) {
			if (field) {
				this.$delete(this.errors, [field]);
			}
		},
		clearAllErrors() {
			this.errors = {};
		},
		clearForm() {
			this.amount = '';
			this.password = '';
		},
		verifyTransfer() {
			if (this.selectedPayoutMethod === 'ipayout') {
				const trans = {
					title: this.translate('transfer_balance'),
					text: this.translate('withdraw_text'),
				};
				const options = {
					confirmButtonText: this.translate('confirm'),
					cancelButtonText: this.translate('cancel'),
				};
				this.alert.confirmation(trans.title, trans.text, options).then(() => {
					this.makeTransfer();
				}).catch(() => {});
			}
		},
		verifyWithdrawal() {
			if (this.selectedPayoutMethod === 'tw_local_eft') {
				const trans = {
					title: this.translate('request_withdrawal'),
					text: this.translate('tw_local_eft_withdraw_text', {
						amount: Number(this.amount).toFixed(2),
						accountName: this.savedBankInfo.account_name,
						accountNumber: this.savedBankInfo.account_number,
						bankName: this.bankCode,
						branchCode: this.branchCode,
					}),
				};
				const options = {
					confirmButtonText: this.translate('confirm'),
					cancelButtonText: this.translate('cancel'),
				};
				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
					this.makeTransfer();
				}).catch(() => {});
			}
		},
		makeTransfer() {
			const payload = {
				payout_method: this.selectedPayoutMethod,
				transaction_password: this.password,
				amount: this.amount,
			};

			this.clearAllErrors();
			this.transfer.transferBalance(payload).then(() => {
				this.alert.toast('success', this.translate('withdraw_success'));
				this.clearForm();
			}).catch(() => {
				if (!Object.keys(this.errors).length) {
					this.alert.toast('error', this.translate('something_went_wrong'));
				}
			}).finally(() => {
				this.summary.getBalance();
				this.$parent.$parent.$parent.summary.getBalance();
			});
		},
		async loginWallet() {
			try {
				const { response } = await this.loginPayout.getPayoutAccount(this.$user.details().id);
				if (response.key.length > 0) {
					this.userKey = response.key;
					document.getElementById('payoutForm').submit();
				}
			} catch (error) {
				document.getElementById('payoutForm').submit();
			}
		},
		showAccountCreatedAlert(showCreatedAlert) {
			if (showCreatedAlert) {
				const trans = {
					title: this.translate('ipayout_created_title'),
					text: this.translate('ipayout_created_message', { companyName: this.companyName, merchantName: this.merchantName, eWalletRoute: this.eWalletRoute }),
				};
				const options = {
					config: {
						icon: 'success',
						confirmButtonText: this.translate('ipayout_created_button'),
						allowOutsideClick: false,
						allowEscapeKey: false,
						confirmButtonColor: '#eb772f',
						input: 'checkbox',
						customClass: {
							input: 'payout-account-created-input',
							actions: 'payout-account-created-actions',
						},
						inputPlaceholder: this.translate('dont_show_message_again'),
					},
				};
				this.alert.confirmation(trans.title, trans.text, options, true).then((result) => {
					if (result.value) {
						this.updateAlert.updatePayoutAccountCreatedAlert(this.$user.details().id);
					}
					this.showVideoModal();
				});
			} else {
				this.showVideoModal();
			}
		},
		showVideoModal(show = false) {
			if (this.canViewVideo) {
				this.video.getVideoProgress(this.$user.details().id, this.videoName).then((response) => {
					this.modalShow = show ? true : response.response.show;
					this.videoData = { ...response.response.data, seen: !response.response.show };
				});
			}
		},
		saveVideoProgress(payload) {
			payload.name = this.videoName;
			this.video.saveVideoProgress(this.$user.details().id, payload);
		},
		closeModal() {
			this.modalShow = false;
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			const hideWalletWithdrawal = vm.$user.details().hide_wallet_withdrawal;

			if (hideWalletWithdrawal) {
				vm.$router.replace({ name: 'Home' });
			}
		});
	},
};
</script>
<style>
/* These styles are not scoped due to sweet-alert-2 not having access to the vue-generated data- hash */
.payout-account-created-input {
	margin-top: 1.875em;
	margin-bottom: 0;
}
.payout-account-created-input span {
	font-size: 0.75em;
}
.payout-account-created-actions {
	margin-top: 0.75em;
}
</style>
